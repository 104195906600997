<template>
  <div>
      <div class="card">
          <div class="bg-blue-light px-1 rounded pb-2">
              <TitleButton
                      class="mt-1"
                      btnTitle="Go Back"
                      title="Create Receipt"
                      @onClickCloseButton="goToList"
              />

              <div class="row mt-1">
                  <div class="cols-12 col-sm-6 col-lg-3">
                      <label for="colFormLabel" class="col-form-label">Date</label>
                      <input
                              class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                              tabindex="1"
                              type="date"
                              placeholder=""
                              v-model="receipt.date"
                      >
                  </div>
                  <div class="cols-12 col-sm-6 col-lg-3">
                      <label for="colFormLabel" class="col-form-label">Voucher</label>
                      <input type="hidden" class="form-control invoice-edit-input date-picker flatpickr-input" readonly="readonly">
                      <div
                          class="input-group input-group-merge invoice-edit-input-group has-validation"
                          :class="{'is-invalid': isEmptyVoucher}"
                      >
                          <div class="input-group-text">
                              <span :class="{'text-danger': isEmptyVoucher}">{{ receipt.voucher_prefix }}-</span>
                          </div>
                          <input
                              type="text"
                              class="form-control invoice-edit-input"
                              placeholder=""
                              aria-describedby="validationVoucherNameFeedback"
                              v-model="receipt.voucher_serial"
                              :class="{'is-invalid': isEmptyVoucher}"
                          >
                      </div>
                  </div>
                  <div class="cols-12 col-sm-6 col-lg-3">
                      <label for="colFormLabel" class="col-form-label">Received in</label>
                      <v-select
                              placeholder="Select Cash & Bank Account"
                              class="w-100"
                              v-model="debit.account_head_id"
                              :options="cashAndBack"
                              label="name"
                              :reduce="name => name.id"
                      />
                  </div>
                  <div class="cols-12 col-sm-6 col-lg-3">
                      <label for="colFormLabel" class="col-form-label">Receipt Reference date </label>
                      <input
                              class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                              tabindex="1"
                              type="date"
                              placeholder=""
                              v-model="receipt.payment_ref_date"
                      >
                  </div>
                  <div class="cols-12 col-sm-6 col-lg-3">
                      <label for="colFormLabel" class="col-form-label">Receipt Reference</label>
                      <input
                              type="text"
                              class="form-control invoice-edit-input"
                              placeholder="Receipt Reference"
                              v-model="receipt.payment_ref">
                  </div>
                  <div class="cols-12 col-sm-6 col-lg-3">
                      <label for="colFormLabel" class="col-form-label">Project</label>
                      <v-select
                              placeholder="Select Project"
                              v-model="projectId"
                              :options="projects"
                              label="name"
                              :reduce="name => name.id"
                              @option:selected="onSelectProject"
                      />
                  </div>
                  <div class="cols-12 col-sm-6 col-lg-3">
                      <label for="colFormLabel" class="col-form-label">Donors</label>
                      <v-select
                              placeholder="Select Donor"
                              class="w-100"
                              :options="donors"
                              label="name"
                              :reduce="name => name.id"
                              v-model="donorId"
                      />
                  </div>
                  <div class="cols-12 col-sm-6 col-lg-3">
                      <label for="colFormLabel" class="col-form-label">Cost Centre</label>
                      <v-select
                              placeholder="Select Cost Centre"
                              v-model="costCentresId"
                              :options="costCentres"
                              label="name"
                              :reduce="name => name.id"
                      />
                  </div>
                  <div class="cols-12 col-sm-6 col-lg-3">
                      <label for="colFormLabel" class="col-form-label">Party</label>
                      <v-select
                              placeholder="Select Party"
                              v-model="debit.contact_profile_id"
                              :options="contactProfiles"
                              label="name"
                              :reduce="name => name.id"
                      />
                  </div>
                  <div class="cols-12 col-sm-6 col-lg-3">
                      <label for="colFormLabel" class="col-form-label">Business</label>
                      <v-select
                              placeholder="Select Business"
                              class="w-100"
                              :options="businesses"
                              label="name"
                              :reduce="name => name.id"
                              v-model="debit.business_id"
                      />
                  </div>
              </div>
          </div>
          <div class="card p-2">
              <AddFormElement
                      class="mb-2"
                      :accountHeads="accountHeads"
                      :ngoabHeads="ngoabHeads"
                      :budgets="budgets"
                      :programs="programs"
                      :projects="projects"
                      :businesses="businesses"
                      :costCentres="costCentres"
                      :contactProfiles="contactProfiles"
                      :paymentDate="receipt.date"
                      v-for="(item, index) in receipt.general_ledgers"
                      :key="index"
                      :index="index"
                      :item="item"
                      @onClose="onClose"
              />

              <div class="row me-1 mt-3">
                  <div class="col-12 col-sm-4 col-lg-8 mb-1 mb-sm-0">
                      <AddButton title="Add line" @onClickAdd="onClickAdd"/>
                  </div>
                  <div class="col-6 col-sm-4 col-lg-4"><input readonly type="number" class="form-control text-end" :value="totalCredit"></div>
              </div>


              <div class="row mt-3">
                  <div class="col-12">
                      <div class="mb-2">
                          <label for="attachment" class="form-label fw-bold">Attachment</label>
                          <BlobFileViewer :attachments="receipt.attachments" />
                          <FileUpload
                                  :is-drag-active="true"
                                  button-text="Upload Documents"
                                  v-model="receipt.attachments"
                          >
                          </FileUpload>
                      </div>
                  </div>
              </div>
              <div class="row mt-3">
                  <div class="col-12">
                      <div class="mb-2">
                          <label for="note" class="form-label fw-bold">Memo</label>
                          <textarea v-model="receipt.note" placeholder="Memo" class="form-control" rows="2" id="note"></textarea>
                      </div>
                  </div>
              </div>

              <div class="mt-2 text-center">
                  <hr class="mb-1">
                  <button
                          :disabled="receiptLoading || isEmptyVoucher"
                          @click="saveReceipt(true)"
                          class="btn btn-primary me-1"
                  >
                      <div v-if="receiptLoading" class="spinner-border spinner-border-sm text-light" role="status">
                          <span class="visually-hidden">Loading...</span>
                      </div>
                      Save
                  </button>
                  <button
                          :disabled="saveNewLoader || isEmptyVoucher"
                          @click="saveReceipt(false)"
                          class="btn btn-primary me-1"
                  >
                      <div v-if="saveNewLoader" class="spinner-border spinner-border-sm text-light" role="status">
                          <span class="visually-hidden">Loading...</span>
                      </div>
                      Save & New
                  </button>
                  <button
                          :disabled="receiptLoading || isEmptyVoucher"
                          @click="saveReceipt(true, true)"
                          class="btn btn-primary me-1"
                  >
                      <div v-if="receiptLoading" class="spinner-border spinner-border-sm text-light" role="status">
                          <span class="visually-hidden">Loading...</span>
                      </div>
                      Save & Print
                  </button>
                  <button
                          :disabled="saveNewLoader"
                          @click="goToList"
                          class="btn btn-outline-secondary cancel-btn"
                  >
                      Cancel
                  </button>
              </div>
          </div>
      </div>
      <VoucherValidationModal
          ref="voucherValidationModal"
          :voucherQuery="voucherQuery"
          @on-press-ok="setVoucherNumberAndDate(true)"
      />
  </div>
</template>

<script setup>
import handleReceipt                             from '@/services/modules/receipt'
import {computed, inject, onMounted, ref, watch} from 'vue'
import handleContact                             from '@/services/modules/contact'
import {generateVoucherNumber, generateQuery} from "@/services/utils/voucherNumberGenerator";

import TitleButton           from '@/components/atom/TitleButton'
import AddFormElement        from '@/components/molecule/ngo/receipt/AddFormElement'
import AddButton             from '@/components/atom/AddButton'
import FileUpload from "@/components/atom/FileUpload";
import BlobFileViewer from "@/components/atom/BlobFileViewer";
import {useRoute, useRouter} from "vue-router";
import handleProjects        from "@/services/modules/procurement/project";
import handleFD6Projects from "@/services/modules/procurement/fd6";
import handleCostCentres     from "@/services/modules/ngo/costCentre";
import handleNGOPrograms from "@/services/modules/ngo/program";
import handleCBusinesses from '@/services/modules/businesses'
import VoucherValidationModal from "@/components/molecule/company/voucher/VoucherValidationModal.vue";
import figureFormatter from "@/services/utils/figureFormatter";
import handleJournal from '@/services/modules/journal'
import pdfPrinterHelper from '@/services/utils/pdfPrinterHelper';

const showError =  inject('showError');
const showSuccess =  inject('showSuccess');
const { fetchCashAndBank, createReceipt, receiptLoading, fetchSingleReceipt} = handleReceipt()
const {fetchContactProfiles} = handleContact()
const {fetchProjects} = handleProjects()
const {fetchCostCentreList} = handleCostCentres()
const {fetchProgramList} = handleNGOPrograms()
const {fetchFD6AccountsAll} = handleFD6Projects()
const {fetchBusinessList} = handleCBusinesses ()
const {decimalFormat} = figureFormatter();
const {fetchAccountHeads} = handleJournal()
const { generatePdf } = pdfPrinterHelper();


const $router = useRouter();
const $route = useRoute();

const cashAndBack = ref([]);
const accountHeads = ref([]);
const contactProfiles = ref([]);
const projects = ref([]);
const costCentres = ref([]);
const donors = ref([]);
const businesses = ref([]);
const ngoabHeads = ref([]);
const budgets = ref([]);
const programs = ref([]);
const projectId = ref(null);
const costCentresId = ref(null);
const donorId = ref(null);
const saveNewLoader = ref(false);
const debit = ref({
  business_id: null,
  account_head_id: null,
  contact_profile_id: null
});
const isEmptyVoucher = ref(false);
const voucherValidationModal = ref(null);
const receipt = ref({
  company_id:0,
  payment_ref: '',
  payment_ref_date: '',
  date: '',
  note: '',
  attachments: [],
  mop_references: 'mop',
  voucher_prefix : '',
  voucher_serial : '',
  voucher_type: 'receipt_voucher',
  voucher_no : '',
  general_ledgers: [
    {
      business_id: null,
      account_head_id: null,
      debit: null,
      credit: null,
      note: '',
      tax_rate: null,
      taxable_amount: null
    }
  ]
})
const receiptRes = ref({});

//computed

const isLoading = computed( () => receiptLoading || saveNewLoader)
const start = computed( () => $route.query.start)
const end = computed( () => $route.query.end)
const general = computed( () => receipt.value.general_ledgers)
const receiptVoucherPrefix = computed( () => receipt.value.voucher_prefix);
const receiptVoucherSerial = computed( () => receipt.value.voucher_serial);
const totalCredit = computed( () => {
  let total = 0;
  general.value.map(i => {
    if(i.credit) total += i.credit
  })
  return total
})
const voucherQuery = computed(() => {
    return generateQuery($route.params.companyId, 'receipt_voucher', 'general_receipt')
});

//methods
const concatVoucher = () => {
    isEmptyVoucher.value = false;
    let voucherSerial = decimalFormat(receipt.value.voucher_serial.toString().replace(/[^0-9\.]+/g, ''))
    if(voucherSerial === '') {
        isEmptyVoucher.value = true;
    }
    receipt.value.voucher_serial = voucherSerial;
    receipt.value.voucher_no = `${receipt.value.voucher_prefix}-${voucherSerial}`;
}

const goToPrint = (id) => {
  const companyQuery = `?company_id=${$route.params.companyId}`;
  fetchSingleReceipt(id, companyQuery).then((res) => {
    receiptRes.value = res.data
  }).then(() => {
    generatePdf($route.params.companyId, receiptRes.value, 'receipt')
  }).catch((e) => {
    console.log('Something went wrong')
  })
}
const goToList = () => {
  let params = {
    companyId: $route.params.companyId,
    moduleId: $route.params.moduleId,
    menuId: $route.params.menuId,
    pageId: $route.params.pageId
  }

  let query = {
    start: start.value,
    end: end.value
  }

  $router.push({
    name: 'receipt-voucher-np',
    params: params,
    query: query
  })
}

const onClose = (index) => {
  receipt.value.general_ledgers.splice(index, 1)
}

const onClickAdd = () => {
  receipt.value.general_ledgers.push({
    business_id: null,
    account_head_id: null,
    debit: null,
    credit: '',
    note: '',
    tax_rate: null,
    taxable_amount: null
  })
}

const setVoucherNumberAndDate = (withoutDate = false) => {
  new Promise(async (resolve, reject) => {
    try {
      if (!withoutDate) {
          receipt.value.date = new Date().toISOString().split('T')[0]
      }
      let voucher = await generateVoucherNumber(voucherQuery.value);
      receipt.value.voucher_prefix = voucher.prefix;
      receipt.value.voucher_serial = voucher.serial;
      resolve();
    } catch (err) {
      reject(err);
    }
  })
}

const resetForm = async () => {
    receipt.value = {
        date: '',
        note: '',
        mop_references: 'mop',
        voucher_type: 'receipt_voucher',
        voucher_prefix: '',
        voucher_serial: '',
        general_ledgers: [
            {
                business_id: null,
                account_head_id: null,
                debit: null,
                credit: null,
                note: '',
                tax_rate: null,
                taxable_amount: null
            }
        ]
    }
    await setVoucherNumberAndDate()
}

const getFormData = () => {
  let formData = new FormData();
  Object.keys(receipt.value).forEach(i => {
    if(i !== 'general_ledgers' || i !== 'attachments') {
      formData.append(i, receipt.value[i])
    }
  })
  let generalLedgerData = general.value.slice()
  let mop_account_head_id = debit.value.account_head_id;
  generalLedgerData.forEach(function(generalLedger) {
    generalLedger.mop_account_head_id = mop_account_head_id;
  });
  generalLedgerData.push({
    business_id: debit.value.business_id,
    account_head_id: debit.value.account_head_id,
    project_id: projectId.value,
    donor_id: donorId.value,
    cost_centre_id: costCentresId.value,
    contact_profile_id: debit.value.contact_profile_id,
    debit: totalCredit.value,
    credit: null,
    note: receipt.value.note,
    tax_rate: null,
    taxable_amount: null
  })
  formData.append('general_ledgers', JSON.stringify(generalLedgerData))
  if(receipt.value.attachments) {
    for(let i=0; i<receipt.value.attachments.length; i++) {
      let key = `attachments[${i}]`;
      let value = receipt.value.attachments[i];
      formData.append(key, value);
    }
  }
  return formData;
}

const onSelectProject = () => {
  donors.value = [];
  for (let project of projects.value) {
    if(project.id === projectId.value && project.party.hasOwnProperty('id')){
      donors.value.push(project.party);
      break;
    }
  }
}

const saveReceipt = async (redirect = false, print = false) => {
  receipt.value.company_id = $route.params.companyId;
  let data = getFormData();
  if(redirect) {
    receiptLoading.value = true
  } else {
    saveNewLoader.value = true
  }
  try {
    let res = await createReceipt(data)
    if(!res.status) {
      showError(res.message)
    }
    if(res.status) {
      showSuccess(res.message)
      resetForm()
      if(redirect && print) goToPrint(res.data.id)
    }
    if(redirect){
      $router.push({
        name: 'receipt-voucher-np',
        params: {
          companyId: $route.params.companyId,
          moduleId: $route.params.moduleId,
          menuId: $route.params.menuId,
          pageId: $route.params.pageId,
        },
        query: {
          start: start.value,
          end: end.value
        }
      })                         
  }
  } catch (err) {
    if(!err.response) {
        showError('Something is wrong. Check your connectivity!!')
        return ;
    }

    if(err.response && err.response.status !== 406) {
        showError(err.response?.data.message)
    }
    if(err.response.data.data && err.response.data.data.show_modal) {
        voucherValidationModal.value.openModal(err.response?.data.message)
    }
  } finally {
    receiptLoading.value = false
    saveNewLoader.value = false
  }
}

//Watchers
watch( receiptVoucherPrefix, () => {
  concatVoucher();
})

watch( receiptVoucherSerial, () => {
  concatVoucher();
})

onMounted( () => {
  receiptLoading.value = true
  let companyId = $route.params.companyId;
  let contactId = '';
  let q = '';
  let companyQuery = '?company_id=' + companyId;
  let projectQuery = companyQuery + '&with_donors=1';
  let query = companyQuery + '&q=' + q + '&contact_id' + contactId;
  receipt.value.date = new Date().toISOString().split('T')[0];
  receipt.value.company_id = companyId;
  try {
    Promise.all([
      fetchCashAndBank(companyId)
        .then( res => {
          if(res.data) {
            cashAndBack.value = res.data
          }
        }),
      fetchAccountHeads(companyId, "yes")
        .then( res => {
          if(res.data) {
            accountHeads.value = res.data
          }
        }),
      fetchContactProfiles(query)
        .then( res => {
          if(res.data) {
            contactProfiles.value = res.data;
          }
        }),
      fetchContactProfiles(companyQuery)
        .then( res => {
          if(res.data) {
            contactProfiles.value = res.data;
          }
        }),
      fetchProjects(projectQuery)
        .then( res => {
          if(res.data) {
            projects.value = res.data;
          }
        }),
      fetchFD6AccountsAll(companyQuery)
        .then(res => {
          if(res.data){
            ngoabHeads.value = res.data
          }
        }),
      fetchProgramList(companyQuery).then(res => {
        if(res.data){
          programs.value = res.data;
        }
      }),
      fetchCostCentreList(companyQuery).then(res => {
        if(res.data) {
          costCentres.value = res.data
        }
      }),
      fetchBusinessList(companyQuery).then(res => {
        if(res.data) {
          businesses.value = res.data
        }
      }),
      setVoucherNumberAndDate()
    ])
  } catch ( e ) {
    showError('Something went wrong please try again')
  } finally {
    receiptLoading.value = false
  }
})
</script>

<style scoped>
    .cancel-btn{
        background-color: #F0F2F5; color: #7D7D7D;
    }
    .cancel-btn:hover{
        background-color: #e9edf5;
    }
    hr{
        margin: 0 -3%;
        width: 106%;
    }
</style>
