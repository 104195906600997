<template>
    <div style="border-radius: 8px" class="row journal-line">
        <div class="col-12 d-flex product-details-border position-relative pe-0">
            <div class="w-100 pe-lg-0 me-1 py-2">
                <div class="row">
                    <div class="cols-12 col-sm-6 col-lg-4 col-xl-3 mt-2">
                        <label for="colFormLabel" class="col-form-label">Project</label>
                        <v-select
                            placeholder="Select Project"
                            v-model="selectedProject"
                            :options="projects"
                            label="name"
                            :reduce="name => name.id"
                        />
                    </div>
                    <div class="cols-12 col-sm-6 col-lg-4 col-xl-3 mt-2">
                        <label for="colFormLabel" class="col-form-label">Budget</label>
                        <v-select
                            placeholder="Select Budget"
                            v-model="selectedBudgetMaster"
                            :options="donors"
                            label="name"
                            :reduce="name => name.id"
                        />
                    </div>
                    <div class="cols-12 col-sm-6 col-lg-4 col-xl-3 mt-2">
                        <label for="colFormLabel" class="col-form-label">
                            Activity Head (<a href="#" @click.prevent="showActualDataModal()">{{ commaFormat(budget) }}</a>)
                        </label>
                        <v-select
                            placeholder="Select Donor Activity Head"
                            v-model="selectedActivity"
                            :options="activities"
                            label="name"
                            :reduce="name => name.budget_general_id"
                        />
                    </div>
                    <div class="cols-12 col-sm-6 col-lg-4 col-xl-3 mt-2">
                      <label for="colFormLabel" class="col-form-label">FD6 Budget</label>
                      <v-select
                          placeholder="Select FD6 Budget"
                          v-model="selectedFd6BudgetMaster"
                          :options="fd6Donors"
                          label="name"
                          :reduce="name => name.id"
                      />
                    </div>
                  <div class="cols-12 col-sm-6 col-lg-4 col-xl-3 mt-2">
                        <label for="colFormLabel" class="col-form-label">FD6 Activity</label>
                        <v-select
                            placeholder="Select FD6 Activity"
                            v-model="item.fd6_account_id"
                            :options="ngoabHeads"
                            label="name"
                            :reduce="name => name.id"
                        />
                    </div>
                    <div class="cols-12 col-sm-6 col-lg-4 col-xl-3 mt-2">
                        <label for="colFormLabel" class="col-form-label">Acount Head</label>
                        <v-select
                            placeholder="Select Account Head"
                            v-model="item.account_head_id"
                            :options="accountHeads"
                            label="name"
                            :reduce="name => name.id"
                        />
                    </div>
                    <div class="cols-12 col-sm-6 col-lg-4 col-xl-3 mt-2">
                        <label for="colFormLabel" class="col-form-label">Program</label>
                        <v-select
                            placeholder="Select Program"
                            v-model="item.program_id"
                            :options="programs"
                            label="name"
                            :reduce="name => name.id"
                        />
                    </div>
                    <div class="cols-12 col-sm-6 col-lg-4 col-xl-3 mt-2">
                        <label for="colFormLabel" class="col-form-label">Cost Centre</label>
                        <v-select
                            placeholder="Select Cost Centre"
                            v-model="item.cost_centre_id"
                            :options="costCentres"
                            label="name"
                            :reduce="name => name.id"
                        />
                    </div>
                    <div class="cols-12 col-sm-6 col-lg-4 col-xl-3 mt-2">
                        <label for="colFormLabel" class="col-form-label">Party</label>
                        <v-select
                            placeholder="Select Party"
                            v-model="item.contact_profile_id"
                            :options="contactProfiles"
                            label="name"
                            :reduce="name => name.id"
                        />
                    </div>
                  <div class="cols-12 col-sm-6 col-lg-4 col-xl-3 mt-2">
                    <label for="colFormLabel" class="col-form-label">Business</label>
                    <v-select
                        placeholder="Select Business"
                        class="w-100"
                        :options="businesses"
                        label="name"
                        :reduce="name => name.id"
                        v-model="item.business_id"
                    />
                  </div>
                </div>
                <div class="row mt-2">
                    <div class="col-lg-8 col-12 mb-lg-0 mb-2 mt-lg-0">
                        <textarea
                          v-model="item.note"
                          placeholder="Description"
                          class="form-control"
                          rows="1"
                        >
                        </textarea>
                    </div>
                    <div class="col-lg-4 col-12">
                      <input
                        v-model="item.credit"
                        type="number"
                        class="form-control text-end"
                        placeholder="Amount"
                      >
                    </div>
                </div>
            </div>
            <div
                @click="$emit('onClose', index)"
                class="
                d-flex
                flex-column
                align-items-center
                justify-content-between
                border-start
                invoice-product-actions
                py-50
                px-25"
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x cursor-pointer font-medium-3" ><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
            </div>
        </div>
        <ActualDetailsModal
            ref="actualBudgetModal"
            :activity_details="selectedActivityDetail"
            :actual_cost_details="actualCostDetails"
            :item="item"
        />
    </div>
</template>

<script setup>
import {inject, onMounted, ref, watch} from "vue";
import handleProjects from "@/services/modules/procurement/project";
import figureFormatter from "@/services/utils/figureFormatter";
import handleFD6s from "@/services/modules/procurement/fd6";
import {useRoute} from "vue-router";
import ActualDetailsModal from "@/components/molecule/ngo/payment/ActualDetailsModal";

const showError =  inject('showError');
const showSuccess =  inject('showSuccess');

const $route = useRoute();
const $props = defineProps({
    item: {
      type: Object
    },
    index: {
      type: Number
    },
    ngoabHeads: {
        type: Array,
        default: () => [],
    },
    accountHeads: {
      type: Array
    },
    projects: {
      type: Array
    },
    costCentres: {
      type: Array
    },
    contactProfiles: {
      type: Array
    },
    businesses: {
      type: Array,
      default: () => [],
    },
    programs: {
        type: Array,
        default: () => [],
    },
    paymentDate: {
        type: String,
        default: ''
    }
  })

const {fetchProjectDonors, fetchDonorProjectAccounts, fetchActualCost} = handleProjects();
const {fetchAllFD6Donors, fetchFd6BudgetGeneral} = handleFD6s();
const $emit = defineEmits(['isLoading','loaded','onClose']);
const {commaFormat} = figureFormatter();
const donors = ref([]);
const fd6Donors = ref([]);
const activities = ref([]);
const selectedProject = ref(null);
const selectedBudgetMaster = ref(null);
const selectedFd6BudgetMaster = ref(null);
const selectedActivity = ref(null);
const selectedDonor = ref({});
const selectedActivityDetail = ref({});
let   originalBudget = 0;
const budget = ref(0);
const actualBudgetModal = ref(null);
const actualCostDetails = ref({});

//watchers

watch(selectedProject, (newVal, oldVal) => {
    if (oldVal !== null) {
        deselectedProject()
    }

    if(newVal !== null) {
        $props.item.project_id = newVal
        getProjectDonorLists( $props.item.project_id );
        getAllFd6DonorLists();
    }
});
watch(selectedBudgetMaster, (newVal, oldVal) => {
    let found = false;
    donors.value.map(donor => {
        if (donor.id === newVal) {
            selectedDonor.value = donor;
            $props.item.budget_master_id = donor.id;
            $props.item.donor_id = donor.donor_id;
            $props.item.project_account_id = null;
            $props.item.budget_general_id = null;
            selectedActivity.value = null;
            selectedActivityDetail.value = {};
            budget.value = 0;
            getDonorActivityLists(newVal);
            found = true;
        }
    });

    if(oldVal !== null && (newVal === null || !found)) {
        deselectedBudgetMaster()
    }
});

watch(selectedFd6BudgetMaster, (newVal, oldVal) => {
  let found = false;
  fd6Donors.value.map(donor => {
    if (donor.id === newVal) {
      $props.item.fd6_budget_master_id = donor.id;
      $props.item.fd6_budget_general_id = null;
      getFd6BudgetGeneral(newVal);
      found = true;
    }
  });

  if(oldVal !== null && (newVal === null || !found)) {
    deselectedFd6BudgetMaster()
  }
});

watch(selectedActivity, (newVal, oldVal) => {
    let found = false;
    activities.value.map(activity => {
        if (activity.budget_general_id === newVal) {
            $props.item.budget_general_id = activity.budget_general_id;
            $props.item.project_account_id = activity.id;
            $props.item.account_head_id = activity.account_head;
            $props.item.fd6_account_id = activity.fd6_account_id;
            $props.item.program_id = activity.program_id;
            originalBudget = (typeof activity.budget_amount !== 'undefined') ? activity.budget_amount : 0;
            selectedActivityDetail.value = activity;
            getActualCost(activity);
            found = true;
        }
    });

    if(oldVal !== null && (newVal === null || !found)) {
        deselectedActivity()
    }
});

watch(activities, (newVal, oldVal) => {
    if(activities.value.length > 0 && $props.item.project_account_id) {
        activities.value.map(activity => {
            if (activity.id === $props.item.project_account_id) {
                originalBudget = (typeof activity.budget_amount !== 'undefined') ? activity.budget_amount : 0;
                getActualCost(activity);
            }
            if (selectedActivity.value !== null && activity.budget_general_id === selectedActivity.value) {
                selectedActivityDetail.value = activity;
                getActualCost(activity);
            }
        });
    }
})

const deselectedProject = () => {
    selectedBudgetMaster.value = null;
    $props.item.project_id = null
    donors.value = []

    deselectedBudgetMaster()
}

const deselectedBudgetMaster = () => {
    selectedDonor.value = {};
    $props.item.budget_master_id =null;
    $props.item.donor_id = null;
    selectedActivity.value = null;
    activities.value = []

    deselectedActivity()
}

const deselectedFd6BudgetMaster = () => {
  $props.item.fd6_budget_master_id =null;
  $props.item.fd6_budget_general_id = null;
}

const deselectedActivity = () => {
    $props.item.budget_general_id =null;
    $props.item.project_account_id =null;
    $props.item.account_head_id =null;
    $props.item.fd6_account_id =null;
    $props.item.program_id =null;
    originalBudget = 0;
    selectedActivityDetail.value = null;
    actualCostDetails.value = {};
    budget.value = 0
}

const getProjectDonorLists = async (projectId) => {
    $emit('isLoading');
    let companyQuery = '?company_id=' + $route.params.companyId;
    try {
        let res = await fetchProjectDonors(projectId, companyQuery)
        if(res.status) {
            donors.value = res.data
        }
    } catch (err) {
        if(!err.response) {
            showError('Something is wrong. Check your connectivity!!')
        }
        if(err.response) {
            showError(err.response?.data.message)
        }
    }
    $emit('loaded');
}

const getAllFd6DonorLists = async () => {
  $emit('isLoading');
  let companyQuery = '?company_id=' + $route.params.companyId;
  try {
    let res = await fetchAllFD6Donors(companyQuery)
    if(res.status) {
      fd6Donors.value = res.data
    }
  } catch (err) {
    if(!err.response) {
      showError('Something is wrong. Check your connectivity!!')
    }
    if(err.response) {
      showError(err.response?.data.message)
    }
  }
  $emit('loaded');
}

const getFd6BudgetGeneral = async (fd6BudgetMasterId) => {
  $emit('isLoading');
  let companyQuery = '?company_id=' + $route.params.companyId+ `&fd6_account_id=${$props.item.fd6_account_id}`;
  try {
    let res = await fetchFd6BudgetGeneral(fd6BudgetMasterId, companyQuery)
    if(res.status) {
      $props.item.fd6_budget_general_id = res.data.id
    }else{
      showError(res.message);
    }
  } catch (err) {
    if(!err.response) {
      showError('Something is wrong. Check your connectivity!!')
    }
    if(err.response) {
      showError(err.response?.data.message)
    }
  }
  $emit('loaded');
}
const getDonorActivityLists = async (budgetMasterId) => {
    activities.value = [];
    $emit('isLoading');
    let companyQuery = '?company_id=' + $route.params.companyId+ `&voucher_date=${$props.paymentDate}`;
    try {
        let res = await fetchDonorProjectAccounts(budgetMasterId, companyQuery)
        if(res.status) {
            activities.value = res.data
        }
    } catch (err) {
        if(!err.response) {
            showError('Something is wrong. Check your connectivity!!')
        }
        if(err.response) {
            showError(err.response?.data.message)
        }
    }
    $emit('loaded');
}

const checkLimit = () => {
    if($props.item.debit > budget.value)
        confirm('Amount is greater than budget value. Do you want to continue?')
}

const getActualCost = async (activity) => {
    if(activity.id != null){
        $emit('isLoading');
        actualCostDetails.value = {};
        let companyQuery = `?company_id=${$route.params.companyId}&range=${activity?.budget_range}`;
        if(selectedDonor.value.party) {
          companyQuery += `&donor_id=${selectedDonor.value.party}`;
        }
        if(selectedDonor.value.cost_centre_id) {
          companyQuery += `&cost_centre_id=${selectedDonor.value.cost_centre_id}`;
        }

        try {
            let res = await fetchActualCost(activity.id, companyQuery)
            if(res.status) {
                actualCostDetails.value = res.data;
                budget.value = originalBudget - res.data.total;
                budget.value += $props.item?.old_debit ?? 0
            }
        } catch (err) {
            if(!err.response) {
                showError('Something is wrong. Check your connectivity!!')
            }
            if(err.response) {
                showError(err.response?.data.message)
            }
        }
        $emit('loaded');
    } else {
        budget.value = originalBudget
    }
}

const showActualDataModal = async () => {
    if (selectedActivity.value === null) {
        showError("Please select a Activity Head first");
        return;
    }
    if(actualBudgetModal.value !== null)
        actualBudgetModal.value.toggleModal();
}

onMounted(() => {
    if($props.item.project_id) {
        getProjectDonorLists( $props.item.project_id );
        selectedProject.value = $props.item.project_id;
    }
    if($props.item.budget_master_id) {
        getDonorActivityLists( $props.item.budget_master_id );
        selectedBudgetMaster.value = $props.item.budget_master_id;
        selectedActivity.value = $props.item.budget_general_id;
        getAllFd6DonorLists();
        selectedFd6BudgetMaster.value = $props.item.fd6_budget_master_id;
    }
});
</script>
<style scoped>
.journal-line{
        border: 1px solid #f0f2f5;
    }
    .journal-line:hover{
        background: #f0f2f5;
    }
</style>
